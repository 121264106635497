<template>
  <div>
    <v-row>
      <v-col>
        <v-card class="mx-auto" outlined>
          <v-form @submit.prevent="lookFor">
            <v-card-title>
              Search
            </v-card-title>
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="3">
                  <v-select
                    v-model="searchTypeValue"
                    :items="searchType"
                    label="Content Type"
                    single-line
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="7">
                  <v-text-field
                    v-model="query"
                    label="Title / Description"
                    :error-messages="minLengthErrorMsg"
                  ></v-text-field>
                </v-col>
                <v-col class="d-flex" cols="12" sm="2">
                  <button type="submit" class="custom__btn">
                    Search <v-icon right>mdi-magnify</v-icon>
                  </button>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-text>
            <v-data-table
              multi-sort
              :sort-by="['publishedDate', 'title']"
              :sort-desc="[true, false]"
              :key="search_results.unique_id"
              :items="search_results"
              :headers="table_headers"
              :items-per-page="10"
              :loading="loading"
              :page.sync="currentPage"
              hide-default-footer
              loading-text="Searching..."
              no-data-text="No results. Please try again with different keywords."
            >
              <template v-slot:item.action="{ item }">
                <td class="d-flex justify-center align-items-center">
                  <div v-if="item.deleted !== '0'">
                    <v-tooltip v-if="(isAdmin === true || item.canHide === true)" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="newswav--text"
                          color="#FFE1E1"
                          @click="show(item)"
                          v-bind="attrs"
                          v-on="on"
                          >Unhide</v-btn
                        >
                      </template>
                      <span>Unhide Content</span>
                    </v-tooltip>
                    <v-tooltip
                      v-else
                      bottom
                      color="error"
                      max-width="300"
                    >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="warning" dark v-bind="attrs" v-on="on">
                        mdi-alert
                      </v-icon>
                    </template>
                      <span
                        >This content has been flagged and hidden.
                        Please contact partner@newswav.com for assistance.</span
                      >
                    </v-tooltip>
                  </div>

                  <v-btn color="#EC3535" v-else @click="hide(item)" dark
                    >Hide</v-btn
                  >
                </td>
              </template>
            </v-data-table>
            <div class="text-center pt-2">
              <v-pagination
                @input="searchByPage(query, currentPage)"
                @next="searchByPage(query, currentPage)"
                @previous="searchByPage(query, currentPage)"
                v-model="currentPage"
                :length="pages"
              ></v-pagination>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Search',
  data() {
    return {
      max_pages: 5,
      query: '',
      currentPage: 1,
      pages: 1,
      table_headers: [
        { text: 'Published Date', value: 'publishedDate' },
        { text: 'Title', value: 'title' },
        { text: 'Unique ID', value: 'uniqueID' },
        { text: 'Publisher', value: 'publisher' },
        { text: 'Action', filterable: false, sortable: false, value: 'action' },
      ],
      searchType: ['Unique ID', 'Articles', 'Videos', 'Podcasts'],
      searchTypeValue: 'Articles',
      minLengthErrorMsg: '',
    };
  },
  computed: {
    searchTypeTransformed() {
      return this.searchTypeValue.toLowerCase().split(' ').join('_');
    },
    loading() {
      return this.$store.getters.getLookLoading;
    },
    search_results() {
      return this.$store.getters.getLookResults;
    },
    isAdmin() {
      return this.$store.getters.getIsAdmin;
    },
  },
  created() {
    this.$store.commit('SET_LOOK_FOR_RESULT', []);
  },
  methods: {
    lookFor() {
      // reset form validation
      if (this.validateQuery(this.query)) {
        this.minLengthErrorMsg = '';
      }

      this.currentPage = 1;
      this.pages = 1;
      this.$store.commit('SET_LOOK_FOR_RESULT', []);
      let keyword = this.query;
      // if (/^A\d{4}_[0-9a-zA-Z]{6,}/g.test(keyword)) {
      if(this.searchTypeTransformed == 'unique_id') {
        let send = this.query;
        this.$store.dispatch('searchByUnique', send);
      } else {
        let send = { keyword: this.query, type: this.searchTypeTransformed };
        if (!this.validateQuery(keyword)) {
          return;
        }

        this.$store
          .dispatch('searchArticle', send)
          .then(() => {
            let nextPage = this.pages + 1;
            this.callAhead(keyword, nextPage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    searchByPage(search, newPage) {
      let keyword = search;
      if (!/^A\d{4}_[0-9a-zA-Z]{6,}/g.test(keyword)) {
        let send = {
          keyword: this.query,
          type: this.searchTypeTransformed,
          page: newPage,
        };
        if (!this.validateQuery(keyword)) {
          return;
        }

        this.$store
          .dispatch('searchArticle', send)
          .then(() => {
            this.callAhead(keyword, newPage);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    callAhead(keyword, newPage) {
      if (this.pages <= this.max_pages) {
        this.$store
          .dispatch('searchArticle', {
            keyword: keyword,
            type: this.searchTypeTransformed,
            page: this.pages,
            skip: true,
          })
          .then((res) => {
            if (res.length > 0) {
              if (newPage >= this.pages && newPage <= this.max_pages) {
                if (newPage !== this.max_pages) {
                  this.pages = this.pages + 1;
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    hide(item) {
      let msg = 'This will hide article from users. Continue?';
      if (confirm(msg)) {
        let send = { unique_id: item.uniqueID };
        this.$store
          .dispatch('hideData', send)
          .then((res) => {
            alert(res.data.msg);
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    show(item) {
      let msg = 'This will unhide article from users. Continue?';
      if (confirm(msg)) {
        let send = { unique_id: item.uniqueID, channelID: item.channelID };
        this.$store
          .dispatch('showData', send)
          .then((res) => {
            alert(res.data.msg);
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    validateQuery(q) {
      const REGEX_CHINESE = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/;
      const hasChineseChar = q.match(REGEX_CHINESE);
      if (hasChineseChar) {
        if (q.length < 2) {
          this.minLengthErrorMsg = 'Minimum length is 2 character';
          return false;
        }
      } else {
        if (q.length < 3) {
          this.minLengthErrorMsg = 'Minimum length is 3 character';
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style scoped>
.newswav--text {
  color: #ec3535;
}

.custom__btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #000000;
  padding: 6px 15px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  letter-spacing: 0.0892857143em;
}
</style>
